<template>
  <b-card no-body>
    <b-card-body>
      <b-row>

        <b-col
          v-if="$can('audit', 'all')"
          cols="12"
          md="3"
          class="mb-1"
        >
          <feather-icon
            icon="FilterIcon"
            size="16"
          />&nbsp;
          <label>{{ $t('Branch') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="branchFilter"
            :options="branchOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:branchFilter', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="mb-1"
        >
          <feather-icon
            icon="FilterIcon"
            size="16"
          />&nbsp;
          <label>{{ $t('Receiver') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="staffFilter"
            :options="staffOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:staffFilter', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="mb-1"
        >
          <feather-icon
            icon="FilterIcon"
            size="16"
          />&nbsp;
          <label>{{ $t('Reason') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="reasonFilter"
            :options="reasonOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:reasonFilter', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="mb-1"
        >
          <feather-icon
            icon="FilterIcon"
            size="16"
          />&nbsp;
          <label>{{ $t('Item') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="itemFilter"
            :options="itemOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:itemFilter', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="mb-1"
        >
          <feather-icon
            icon="FilterIcon"
            size="16"
          />&nbsp;
          <label>{{ $t('Date') }}</label>
          <b-input-group class="input-group-merge">
            <flat-pickr
              :value="dateFilter"
              class="form-control"
              :config="{ mode: 'range'}"
              :reduce="val => val.value"
              @input="(val) => $emit('update:dateFilter', val)"
            />
            <b-input-group-append>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :value="dateFilterValue"
                variant="outline-primary"
                @click="searchDate"
              >
                <feather-icon
                  icon="SearchIcon"
                />
              </b-button>
            </b-input-group-append>

            <b-input-group-append>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="updateDate"
              >
                <feather-icon
                  icon="RotateCcwIcon"
                />
              </b-button>
            </b-input-group-append>
          </b-input-group>

        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BInputGroup, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    vSelect,
    flatPickr,
    BInputGroup,
    BInputGroupAppend,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    branchFilter: {
      type: [String, null],
      default: null,
    },
    branchOptions: {
      type: Array,
      required: true,
    },
    staffFilter: {
      type: [String, null],
      default: null,
    },
    staffOptions: {
      type: Array,
      required: true,
    },
    reasonFilter: {
      type: [String, null],
      default: null,
    },
    reasonOptions: {
      type: Array,
      required: true,
    },
    itemFilter: {
      type: [String, null],
      default: null,
    },
    itemOptions: {
      type: Array,
      required: true,
    },
    dateFilter: {
      type: [String, null],
      default: null,
    },
    dateFilterValue: {
      type: [String, null],
      default: null,
    },
  },
  methods: {
    updateDate() {
      this.$emit('update:dateFilter', null)
      this.$emit('update:dateFilterValue', null)
    },
    searchDate() {
      this.$emit('update:dateFilterValue', this.dateFilter)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
